.change-pass {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 480px;
  background-color:  var(--thirdColor);
  padding: 20px 30px;
  border-radius: 7px;
}

.form-close h2 {
font-size: 30px;
}

.change-pass input[type="password"] {
  height: 30px;
  background-color:  var(--thirdColor);
  border: none;
  border-bottom: 1px solid black;
}

.change-pass input[type="password"]:focus {
  background-color: white;
  outline: var(--mainColor) 2px solid;
  border-radius: 5px;
  border-bottom: none;
}

.change-pass button {
  width: 200px;
  height: 40px;
  background-color: var(--mainColor);
  border: none;
  font-size: 20px;
  color: white;
  align-self: center;
}

.change-pass div {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.change-pass label {
  width: 170px;
  font-size: 20px;
}

.change-pass input {
  height: 30px;
  flex: 1;
  margin-left: 10px;
  width: 272px !important;
}



/* < MODAL > */

.modal {
  /* display: none; */
  position: fixed; 
  z-index: 1; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background: linear-gradient(180deg, rgba(82,167,225,0.4), rgba(0,0,0,0.6));
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 600px;
  height: 365px;
  margin-top: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-content .form-close {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.pass-close, .category-close, .tag-close {
  color: #aaa;
  float: right;
  font-size: 30px;
  font-weight: bold;
}

.pass-close:hover, .pass-close:focus,
.category-close:hover, .category-close:focus,
.tag-close:hover, .tag-close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

#passModal:target, #categoryModal:target, #tagModal:target {
  display: block;
}

.modal-content p {
  font-size: 20px;
}

/* </ MODAL > */

/* < ICON > */

.check, .cross {
  left: 285px;
}

/* </ ICON > */
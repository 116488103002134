.settings-body {
    /* margin: 0 auto;
    width: 100%; */
    margin-top: 200px;
}

.table {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.table thead th {
    font-size: 30px;
    padding-top: 40px;
}

.table-title {
    font-size: 40px !important;
}

.row {
    border: 1px solid black;
    border-right: none;
    border-left: none;
}

.row th {
    font-size: 20px;
}

.row:first-child, .row:last-child {
    border: none;
}


/* < PERSONAL-INFORMATION.HTML > */

.pi tbody .row th {
    text-align: right;
    padding: 20px;
    color: var(--mainColor);
    padding-left: 188px;
}

.pi .row td {
    padding: 20px;
    padding-right: 188px;
    font-size: 20px
}

.pi .table-button {
    background-color: var(--mainColor);
    border: none;
    height: 27px;
    width: 150px;
    font-size: 20px;
    border-radius: 5px;
    margin: 0;
}

.pi .table-button a {
    color: white;
    text-decoration: none;
}

.pi .row td.td-button {
    padding-right: 50px;
}

.pi .table-button:active {
    box-shadow: inset 0px 0px 5px #797979;
}

/* </ PERSONAL-INFORMATION.HTML > */


/* < COMPANY-DATA.HTML > */

.cd .row th {
    text-align: right;
    padding: 20px;
    color: var(--mainColor);
}

.cd .row td {
    padding: 20px;
    font-size: 20px;
}

.settings-body .row th {
    padding-left: 346px;
}

.settings-body .row td {
    padding-right: 346px;
}

.tbOverflow{
    max-width: 280px;
    overflow-x: auto;
}
.closeSpan{
    text-align: right;
    color: var(--mainColor);
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
}
.rowSpanWidth{
    white-space: nowrap;
    display: block;
}
.editDivValue{
    padding: 0px 0px 0px 2%;
}
.editDiv{
    height: 120px;
    overflow-y: auto;
}
.editDivLabel{
    font-size: 20px;
}
.userListValue{
    text-align: left !important; 
    padding-left: 50px !important;
    max-width: 350px !important;
    overflow-x: auto !important;
}
.td-select{
    margin: 0;
}
.userListButton{
    text-align: right;
}

.cd .row td a {
    text-decoration: none;
    color: black;
}

hr {
    border: 1px solid var(--mainColor);
}

.centered {
    text-align: center;
}

.centered .row td:first-child, .centered .row th:first-child {
    padding: 20px 50px 20px 335px;
}

.centered .row td, .centered .row th {
    padding: 20px 335px 20px 50px;
}

/* </ COMPANY-DATA.HTML > */

.company-change-button{
    /* margin: right; */
    color: white;
    background-color: var(--mainColor);
    border: none;
    height: 27px;
    width: 150px;
    font-size: 20px;
    border-radius: 5px;
    margin: 0;
}
#openModalLink{
    color: white;
}
.color{
    width: 100px;
    margin-right: 150px;
}
#color{
    width: 100px;
    margin-right: 50px;
}
.td2-change-button{
    color: white;
    background-color: var(--mainColor);
    border: none;
    height: 27px;
    width: 140px;
    font-size: 20px;
    border-radius: 5px;
    margin: 0;
    margin-right: 5px;
}
/* < RESPONSIVE > */
#uploadDiv{
    background-color: var(--mainColor);
    height: 100px;
    width: 200px;
    float: left;
    display: -webkit-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;

}
.logoOutputButton{
    color: white;
    background-color: var(--mainColor);
    border: none;
    height: 27px;
    width: 140px;
    font-size: 20px;
    border-radius: 5px;
    margin-left: 10px;
    margin-right: 5px;
    display: inline-block;
    float: right;
    box-sizing: border-box;
    margin-top: 73px;
}
/* .NewDepartment{
    display: block;
    width: 70px;
} */

.th-middle{
    padding: 20px 20px 20px 50px !important;  
}
.td-middle{
    padding: 20px 20px 20px 50px !important;  
}
.departmentButton button{
    color: white;
    background-color: var(--mainColor);
    border: none;
    height: 27px;
    width: 140px;
    font-size: 20px;
    border-radius: 5px;
    margin-left: 10px;
    /* margin-right: 5px; */
    box-sizing: border-box;
}
.department-input{
    font-size: 17px;
    padding: 5px;
    border: var(--mainColor) 1px solid;

}

.adminKategorie{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    margin-top: 200px;
    min-height: calc(100vh - 3em - 200px);

  }

/* personal information */
@media screen and (max-width: 1230px) {
    .pi tbody .row td {
        padding-right: 0 !important;
    }
}

@media screen and (max-width: 800px) {
    .pi tbody .row th {
        padding-left: 0 !important;
    }
}

@media screen and (max-width: 610px) {
    .pi tbody .row th {
        font-size: 13px;
    }
    .pi tbody .row td {
        font-size: 13px;
    }
    .pi .table-button {
        padding: 1px;
        width: auto;
        font-size: 13px;
    }
}

/* company data */
@media screen and (max-width: 1230px) {
    .cd tbody .row td, .cd tbody .row th {
        padding-right: 20px !important;
        padding-left: 20px !important;
    }
}

@media screen and (max-width: 610px) {
    .cd tbody .row th, .cd tbody .row td {
        font-size: 13px;
    }
}

/* </ RESPONSIVE > */
/* < STRUCTURE > */

.prompt-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    margin-top: 200px;
}

.prompt-content {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
}

.categorytags {
    flex: 1;
}

.prompt-section {
    flex: 4;
    border-left: var(--mainColor) 3px solid;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.categorytags form {
    display: flex;
    flex-direction: column;
}

.characteristics, .categorytags, .prompt-title {
    width: 80%;
}

.category  {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.subcategory {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
}

.category-added {
    margin-top: 10px;
    background-color: var(--mainColor);
    /* background-color: #00ccff;
    background-color: #85A7E4;
    background-color: #739BE0; */
    padding: 8px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    max-width: 90%;
    margin: 10px auto 0px auto;
}

.category-full {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-right: 5px;
}

div .tag-added {
    margin-top: 10px;
    background-color: var(--mainColor);
    /* background-color: #00ccff;
    background-color: #85A7E4; 
    background-color: #739BE0; */
    padding: 8px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.tags {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
}
.tags span{
    white-space: nowrap;
}


/* </ STRUCTURE > */


/* < TITLE > */

.prompt-title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    font-size: 40px;
    text-align: center;
    
}

.temp-checkBox{
    display: inline-block;
    padding: 5px;
    border: 1px solid var(--mainColor) ;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    /* float: right; */

}

/* </ TITLE > */


/* < CATEGORY, SUBCATEGORY & TAGS > */

.category label, .subcategory label, .tags label {
    margin-bottom: 8px;
}

/* .category select, .subcategory select {
    width: 200px;
    margin: 0 20px;
} */

.tags input[type="text"] {
    width: 208px;
    margin: 0 20px;
    height: 28px;
}

button.add-tag {
    margin-top: 0 !important;
}

/* </ CATEGORY, SUBCATEGORY & TAGS > */


/* < THE REST OF THE CODE > */

/* select option:checked {
    background-color: var(--mainColor);
    color: #fff;
} 
.characteristics select {
    width: 250px;
} */

.characteristics {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.characteristics .bluebg {
    background-color:  var(--thirdColor);
    padding: 30px 50px;
    margin-top: 20px;
}

.characteristics form .bluebg {
    display: flex;
    flex-direction: column;
}

label {
    font-size: 30px;
}

.labelquestion {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.labelquestion label {
    font-size: 30px;
}

.labelquestion span {
    font-size: 30px;
    font-weight: bold;
}

.labelquestion:first-of-type {
    margin-top: 0;
}

textarea {
    resize: none;
    font-family: arial;
}

.characteristics form input[type="text"], textarea, /*select,*/ #tags {
    font-size: 17px;
    padding: 5px;
    border: var(--mainColor) 1px solid;
    margin-bottom: 10px;
}

input[type="text"]:focus, .characteristics textarea:focus/*, select:focus*/ {
    outline: var(--mainColor) 2px solid;
}

#tags:focus {
    box-shadow: var(--mainColor) 0 0 3px;
    outline: none !important;
    background-color:  var(--thirdColor);
}

/* </ THE REST OF THE CODE > */


/* < BUTTONS > */

.prompt-body button {
    background-color: var(--mainColor);
    border: none;
    color: white;
    height: 27px;
    width: 202px;
    font-size: 20px;
    margin-top: 10px;;
}

button.create, button.save {
    width: 220px;
    height: 40px;
    margin: 25px 0;
    font-size: 20px;
}

button:disabled {
    background-color:  var(--secondColor);
}

a.prompt-up {
    background-color: var(--mainColor);
    border: none;
    color: white;

    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    position: fixed;
    bottom: 60px;
    right: 55px;

    text-decoration: none;
}

button:hover {
    background-color:  var(--secondColor);
}

/* </ BUTTONS > */


/* < ROUNDED DESIGN & SHADOWS > */

input[type="text"], textarea, /*select,*/  button {
    border-radius: 5px;
}

.bluebg {
    box-shadow: var(--mainColor) 0px 0px 3px;
}

.add-tag:active, .create:active, .save:active {
    box-shadow: inset 0px 0px 5px #797979;
}

/* </ ROUNDED DESIGN & SHADOWS > */


/* < SELECT > */

select {
    padding: 8px;
    font-size: 17px;
    border: 1px solid  var(--secondColor);
    border-radius: 5px;
    background-color: #fcfcfc;
    color: #333;

    &:focus {
        border-color: var(--mainColor);
    }
}

select option {
    padding: 8px;
    font-size: 17px;
    background-color: #fff;
    color: black;
    box-shadow: var(--mainColor) 1px 1px 1px;
}

/*********************************************/

.category select, .subcategory select {
    width: 200px;
    margin: 0 20px;
}

select option:checked {
    background-color: var(--mainColor);
    color: #fff;
}

.characteristics select {
    width: 250px;
}

select {
    padding: 5px;
    border: var(--mainColor) 1px solid;
    margin-bottom: 10px;
}

select {
    border-radius: 5px;
}

select:focus {
    outline: var(--mainColor) 2px solid;
}

/* </ SELECT > */


/* < SELECT DROPDOWN > */

.select-box {
    position: relative;
    width: 220px;
    background-color: #fcfcfc;
}

.select-option {
    position: relative;
    color: #000;
    border-radius: 7px;
    cursor: pointer;
    padding: 8px 50px 8px 20px;
    border: 1px solid var(--mainColor);
}

.select-option input {
    width: 100%;
    font-size: 17px;
    outline: 0 !important;
    border: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.select-option:after {
    content: "";
    border-top: 12px solid #000;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    position: absolute;
    left: 180px;
    top: 22px;
    margin-top: -8px;
}

.content {
    background-color: #fff;
    position: absolute;
    color: #000;
    border-radius: 0 0 7px 7px;
    z-index: 998;
    padding: 0 10px 10px 20px;
    display: none;
    box-shadow: var(--mainColor) 0 0 3px;
}

.subcategory .content {
    background-color: #fff;
    position: absolute;
    color: #000;
    border-radius: 0 0 7px 7px;
    z-index: 998;
    padding: 0px 10px 10px 20px;
    display: none;
    box-shadow: var(--mainColor) 0 0 3px;
    width: 190px;
}

#optionSearch {
    margin-top: 20px;
}

.search input {
    width: 80%;
    font-size: 17px;
    padding: 8px;
    outline: 0;
    border: 1px solid var(--mainColor);
    border-radius: 5px;  
}

.options {
    margin-top: 10px;
    max-height: 200px;
    overflow-y: auto;
    padding: 0;
    border-radius: 7px;
    background-color: #fff;
}

.options li {
    padding: 10px 0;
    font-size: 17px;
    cursor: pointer;
    border-bottom: 1px solid var(--mainColor);
    color: #000;
    margin-right: 10px;
}

.options li:hover {
    background-color:  var(--thirdColor);
}

.select-box.active .select-option {
    border-radius: 7px 7px 0 0;
    box-shadow: var(--mainColor) 0 0 3px;
    background-color:  var(--thirdColor);
}

.select-box.active .select-option input, .select-box.active .select-option:after {
    background-color:  var(--thirdColor);
}

.select-box.active .content {
    display: block;
}

.select-box.active .select-option:after {
    transform: rotate(-180deg);
}

/* < SELECT DROPDOWN > */


/* < COPY TEXT > */

.copy-alert {
    padding: 10px;
    text-align: center;
    position: absolute;
    bottom: 7px;
    right: 0;
    color: white;
    background-color: rgba(82, 167, 225, 0.9);
    border: 1px solid #52a7e1;
    border-radius: 5px;
    box-shadow: 0 0 3px var(--mainColor);
    z-index: 990;
}

.hidden {
    display: none;
}

/* </ COPY TEXT > */


/* < DOUBTS > */

.help-div {
    position: absolute;
    right: 240px;
    width: 350px;
    height: auto;
    text-wrap: wrap;
    text-align: justify;
    font-size: 20px;
    background-color: #52a7e1;
    color: white;
    padding: 15px;
    line-height: 25px;
    border-radius: 5px;
    box-shadow: 0 0 3px var(--mainColor);
    z-index: 990;
}

.help-div.hidden {
    display: none;
}

/* </ DOUBTS > */


/* < RESPONSIVE > */

@media screen and (max-width: 730px) {
    .prompt-content {
        flex-direction: column;
        align-items: center;
    }

    .prompt-section {
        border: none;
        width: max-content;
        width: 100%;
    }

    .characteristics .bluebg {
        padding: 20px 30px;
    }

    a.prompt-up {
        bottom: 81px;
        right: 10px;
    }
}

@media screen and (max-width: 375px) {
    .category-added {
        min-width: 320px;
    }
}

/* < RESPONSIVE >*/


/* < ICONS > */

.textarea-container {
    position: relative;
}

.textarea-container textarea, .textarea-container input[type="text"] {
    width: 98.5%;
}

.textarea-image {
    width: 20px;
    position: absolute;
}

.copy-title {
    bottom: 16px;
    right: 10px;
}

.copy-prompt, .copy-output {
    bottom: 25px;
    right: 10px;
}

.copy-title:hover, .copy-prompt:hover, .copy-output:hover {
    width: 21px;
}

.up {
    width: 20px;
}

/* </ ICONS > */
.login-body {
    background-color: var(--mainColor);
    height: 100vh;
}

.login-nav {
    background-color: var(--secondColor);
    height: 135px;
}

.align-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 125px;
    gap: 100px;
}

.image-logo {

    max-width: 200px;
    max-height: 100px;
}

.Language-Box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.languages {
    border: 1px #373737 solid;
    padding: 11px 9px;
    border-radius: 50%;
    position: relative;
    margin: 2px;
}

.languages:hover {
    font-weight: bold;
}

.chosen-login {
    /*This will be changing with JS*/
    color: var(--mainColor);
}

.login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    top: 60px;
}

.login header {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login header h2 {
    margin-bottom: 0px;
    font-size: 40px;
}

.login header p {
    font-size: 20px;
}

.Form--log input {
    padding: 13px;
}

.Form--log input[type="text"], .Form--log input[type="password"] {
    border: 2px #373737 solid;
    width: 230px;
    border-radius: 5px;
    font-size: 17px;
}

.Form--log label {
    color: white;
}

.username, .password {
    display: flex;
    align-items: center;
    font-size: 20px;
    position: relative;
    right: 35px;
}

.username img, .password img {
    width: 50px;
    height: 50px;
    margin-right: 20px;
}

.input-username, .input-password {
    display: flex;
    flex-direction: column;
    margin: 10px 0 10px 0;
    text-align: left;
}

.remember {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.remember label {
    font-size: 20px;
}

#login {
    width: 43%;
    color: white;
    background-color: #373737;
    font-size: 15px;
    height: 40px;
    font-size: 20px;
}

#login:active {
    width: 43%;
    color: white;
    background-color: #373737;
    font-size: 20px;
    height: 40px;
    box-shadow: inset 0px 0px 5px #797979;
}

.button p {
    font-size: 20px;
}

.login-footer {
    position: absolute;
    width: 100%;
    height: 50px;
    bottom: 30px;
} 

.Firmenpunkt-Image {
    position: relative;
    width: 250px;
    height: auto;
} 

.Firmenpunkt-Logo span{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 12px;
}

@media (max-width: 375px) {
    .login {
        top: 0;
    }
}
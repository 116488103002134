.body {
    width: 90%;
}

.view-body {
    width: 80%;
}

.title-view h2 {
    font-size: 40px;
    text-align: center;
}

.title {
    font-size: 26px;
}

.title-view {
    display: flex;
    align-self: flex-end;
    width: 100%;
}

.title-view > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.div-border {
    border: var(--mainColor) 1px solid;
    border-radius: 5px;
    box-shadow: var(--mainColor) 0px 0px 2px;
    padding: 0 25px;
    margin-bottom: 20px;
    text-align: justify;
    width: 100%;
}

.div-border h4 {
    font-size: 30px;
    margin-bottom: 10px;
    margin-top: 15px;
}

.h4-copy {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.edit-options {
    display: flex;
    gap: 10px;
    align-items: center;
}

.div-border p {
    margin-top: 0;
    font-size: 20px;
    line-height: 1.3;
}

.info {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.no-br {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 321px;
    font-size: 20px;
}

.no-br h4, .no-br p {
    margin: 10px 0;
}

.no-br h4{
    margin-right:5px;
}

.no-br-department {
    flex-wrap: wrap;
    display: flex;
    align-items: baseline;
}

.yes-br h4:first-of-type {
    margin-top: 7px !important;
}

.yes-br h4 {
    margin: 20px 0 10px 0;
    font-size: 30px;
}

.buttons button {
    width: 150px;
    background-color: var(--mainColor);
    border: none;
    color: white;
    height: 35px;
    font-size: 20px;
    margin: 10px 0;
}
.div-border img{
    max-height: 35em;
    max-width: 100%;
}

.buttons {
    display: flex;
    gap: 20px;
    justify-content: center;
}

.buttons button:hover {
    background-color:  var(--secondColor);
}

/* < ADDING AND DELETING > */

.tag-list, .added-tag, .category-list, .added-category, .added-subcategory {
    display: flex;
    align-items: center;
}

.added-tag, .added-category {
    background-color: var(--mainColor);
    padding: 5px 8px;
    margin-right: 10px;
    margin-bottom: 10px;
    color: white;
    font-size: 20px;
}

.category-list, .tag-list {
    display: flex;
    flex-wrap: wrap;
}

.added-category span, .added-tag span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    max-width: 525px;
}

/* </ ADDING AND DELETING > */

/* </ PROMPT>*/

p.prompt {
    white-space: pre-line;
    min-height: 2em;
    overflow-y: auto;
    max-height: 12em;
}

/* </ PROMPT>*/

/* < OUTPUT LENGTH > */

p.output {
    padding-right: 30px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 12;
    white-space: pre-line;
    min-height: 2em;
    overflow-y: auto;
    max-height: 14em;
}

p.output br {
    margin-bottom: 20px;
}

/* </ OUTPUT LENGTH > */

/* </ NOTE>*/

p.note {
    white-space: pre-line;
    min-height: 2em;
    overflow-y: auto;
    max-height: 12em;
}

/* </ NOTE>*/


/* < EDIT MODE > */

.edit-mode {
    border: #3fca57 1px solid;
    box-shadow: 0px 0px 1px #228032;
    padding: 10px;
    border-radius: 5px;
    width: calc(100% - 25px);
}

.exit-edit, .save-edit {
    font-size: 20px;
    padding: 2px 20px;
}

.exit-edit {
    background-color: white;
    border: 2px solid #dd4040;
}

.exit-edit:hover {
    background-color: #dd4040;
    border: 2px solid #dd4040;
    color: white;
}

.save-edit {
    background-color: white;
    border: 2px solid var(--mainColor);
}

.save-edit:hover {
    background-color: var(--mainColor);
    border: 2px solid var(--mainColor);
    color: white;
}

.red-cancel, .green-save {
    display: none;
}

/* </ EDIT MODE > */


/* < CATEGORY MODAL > */

.category-modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 390px;
    height: 420px;
    margin-top: 250px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.category-modal-content label {
    font-size: 30px;
}

.category-modal-content .form-close {
    display: flex;
    justify-content: center;
    align-items: baseline;
}

.add-view-category {
    margin-top: 40px;
    background-color: var(--mainColor);
    border: 2px solid var(--mainColor);
    color: white;
    font-size: 20px;
    height: 40px;
    width: 220px;
}

/* </ CATEGORY MODAL > */

/* < TAG MODAL > */

.tag-modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 390px;
    height: 225px;
    margin-top: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tag-modal-content label {
    font-size: 30px;
}

.tag-modal-content .form-close {
    display: flex;
    justify-content: center;
    align-items: baseline;
}

.tag-modal-content .tags {
    margin-top: 0;
}

.tags .add-view-category {
    margin-top: 10px;
}

/* </ TAG MODAL > */


/* < COPY & PASTE > */

.h4-copy .copy-alert, .edit-options .copy-alert {
    padding: 5px;
    text-align: center;
    position: absolute;
    bottom: 0;
    right: 0;
    color: white;
    background-color: rgba(82, 167, 225, 0.9);
    border: 1px solid #52a7e1;
    border-radius: 5px;
    box-shadow: 0 0 3px var(--mainColor);
    z-index: 990;
}

.h4-copy, .h4-copy .edit-options {
    position: relative;
}

/* </ COPY & PASTE > */


/* < ICONS > */

.return {
    width: 50px;
}

.cancel {
    width: 20px;
}

.save {
    width: 22px;
}

.red-cancel {
    width: 20px;
}

.green-save {
    width: 22px;
}

.copy {
    width: 20px;
}

.copy:hover {
    width: 22px;
}

.insert {
    width: 20px;
}

.delete {
    width: 25px;
    margin-left: 5px;
}

.edit:hover {
    width: 26px;
}

.delete-button{
    width: 150px;
    background-color: var(--mainColor);
    border: none;
    color: white;
    height: 35px;
    font-size: 20px;
    margin: 20px auto 10px auto;
}

/* </ ICONS > */
/* < RESPONSIVE > */

@media (max-width: 1570px) {
    .info {
        flex-direction: column;
    }

    .no-br {
        justify-content: start;
    }
}

/* </ RESPONSIVE > */
html, body {
    margin: 0;
    padding: 0;
}

/* HTML5 display-role reset for older browsers */
body {
    line-height: 1;
    font-family: Arial, Helvetica, sans-serif;
    min-height: 79vh;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

.MainShape {
    padding: 1em;
    box-sizing: border-box;
    min-height: calc(100vh - 3em - 200px);
    display: flex;
    justify-content: flex-start;
}
:root {
    --mainColor: #52A7E1;
    --secondColor:  #92BCEA;
    --thirdColor: #e8f0fa;
  }
  
.body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    margin-top: 200px;
}

/* < TEMPORAL SEARCHER > */

.search-container {
  float: right;
}

.topnav input[type=text] {
    padding: 8px 0 8px 20px;
    margin-top: 8px;
    font-size: 17px;
    border: none;
    background-color: #FCFCFC;
    border: 1px solid var(--mainColor);
    border-radius: 5px 0 0 5px;
    min-width: 230px;
    /* max-width: 320px; */
}

.topnav .search-container button {
    float: right;
    padding: 6px 10px;
    margin-top: 8px;
    margin-right: 16px;
    background:  var(--secondColor);
    font-size: 20px;
    border: none;
    cursor: pointer;
    height: 38px;
    border-radius: 0 5px 5px 0;
}

.topnav .search-container button:hover {
    background: var(--mainColor);
}

.topnav input[type=text]:active, .topnav input[type=text]:focus {
    border-radius: 7px 0 0 7px;
    box-shadow: var(--mainColor) 0 0 3px;
    outline: none !important;
}
.filter select{
    padding: 8px 0 8px 4px;
    margin-top: 8px;
    font-size: 17px;
    border: none;
    background-color: #FCFCFC;
    border: 1px solid var(--mainColor);
    width: 200px;
}
/* </ TEMPORAL SEARCHER > */


/* < TITLE AND BOOKMARK > */

.library-title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    font-size: 40px;
}

.title-bookmark {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
}
.title-bookmark span{
    white-space: nowrap;
}

/* .title-bookmark h3:hover::before {
    content: attr(data-title);
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    border: 1px solid var(--mainColor);
    border-radius: 5px;
    box-shadow: 0 0 3px var(--mainColor);
    z-index: 998;
    max-width: 410px;
} */
.title-bookmark .title:hover::before {
    content: attr(data-title);
    font-size: 20px;
    font-weight: normal;
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    padding: 10px;
    background-color: var(--thirdColor);
    border: 1px solid var(--mainColor);
    border-radius: 5px;
    box-shadow: 0 0 3px var(--mainColor);
    z-index: 998;
    max-width: 388px;
    text-decoration: none;
}

/* </ TITLE AND BOOKMARK > */


/* < FILTERS > */

.library-filters {
    display: flex;
    align-items: center;
    justify-content: center;
}

.library-filters > * {
    display: flex;
    flex-direction: column;
    margin: 10px;
    justify-content: center;
}

.search-container label, .filter label {
    font-size: 20px;
}

.library-filters .filter .select-box {
    margin-top: 8px;
}

.filter .select-box.active .select-option {
    background-color: white;
}

.filter .select-box.active .select-option input, .filter .select-box.active .select-option:after {
    background-color: white;
}

.filter .content {
    width: 189px;
}

/* </ FILTERS > */


/* < DISPLAY PROMPTS > */

.all-prompts {
    display: flex;
    justify-content: center;
    max-width: 1396px;
}

.row-prompt {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.long-button {
    background-image: radial-gradient(var(--mainColor),  var(--secondColor));
    width: 85%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: white;
    font-size: 20px;
    border-radius: 7px;
    margin-bottom: 20px;
}

/* </ ALL PROMPTS > */


/* < SAVED PROMPT > */

.prompt-name {
    font-size: 20px;
    margin: 10px 0 10px 0;
}

.saved-prompt h3 {
    margin-top: 0;
    margin-bottom: 5px;
}

.saved-prompt p {
    margin-top: 5px;
    text-align: justify;
    font-size: 20px;
}

.saved-prompt {
    border: 1px solid var(--mainColor);
    padding: 20px;
    width: 410px;
}

.saved-prompt .title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.saved-prompt .prompt {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    height: 4em;
    white-space: pre-line;
}

.index {
    font-size: 12px;
    color: gray;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.index div {
    display: flex;
    align-items: center;
}

.subindex {
    font-size: 12px;
    color: gray;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.subindex a {
    text-decoration: none;
    color: var(--mainColor);
}

/* </ SAVED PROMPT > */

/* ROUNDED DESIGN & SHADOWS */
div, button {
    /* border-radius: 5px; */
}
button {
    border-radius: 5px;
}

.saved-prompt {
    box-shadow: var(--mainColor) 0px 0px 2px;
}

a.lib-up {
    background-color: var(--mainColor);
    border: none;
    color: white;

    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    position: fixed;
    bottom: 60px;
    right: 55px;

    text-decoration: none;
}

/* < ICON > */

    .search-icon {
        width: 18px;
        display: flex;
    }

    .highlighted, .non-highlighted {
        width: 24px;
    }

    .user {
        width: 15px;
    }

/* </ ICON > */


/* < RESPONSIVE > */

/* filters */

.filter {
    display: flex;
}

.filter > * {
    margin-bottom: 0;
}

.hamburger-filter {
    display: none;
    /* top: -41px;
    position: relative;
    left: -161px; */
}

.library-filters {
    background-color:  var(--thirdColor);
    border-radius: 7px;
}

@media screen and (max-width: 1650px) {
    .filter {
        display: none;
    }

    .topnav input[type=text] {
        margin-left: 10px;
        width: 380px;
    }

    .hamburger-filter {
        display: flex;
        background-color:  var(--thirdColor);
        border: none;
        width: 40px;
    }

    .hamburger-filter:hover, .hamburger-filter:active {
        background-color:  var(--thirdColor);
    }

    .library-filters {
        align-items: center;
        flex-wrap: wrap;
        width: 500px;
    }

    .filter {
        margin: 0;
        margin-bottom: 20px;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: left;
    }
}

.medium-screen {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 15px;
}

/* prompts views */

@media screen and (max-width: 1600px) {
    .row-prompt {
        justify-content: center;
    }

    .all-prompts {
        max-width: 924px;
    }
}

@media screen and (max-width: 768px) {
    .row-prompt {
        flex-direction: column;
    }

    .all-prompts {
        max-width: 452px;
    }
}

@media screen and (max-width: 600px) {
    a.lib-up {
        bottom: 81px;
    }
}

@media screen and (max-width: 520px) {
    .saved-prompt {
        max-width: 376px;
    }

    .library-filters {
        max-width: 376px;
    }

    .filter {
        width: 100%;
    }

    .topnav input[type=text] {
        width: 100px;
    }
}

@media screen and (max-width: 395px) {
    .saved-prompt {
        max-width: 300px;
    }
}

/* </ RESPONSIVE > */
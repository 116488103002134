.logout-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    background-color:  var(--secondColor);
    height: 100vh;

    position: fixed; 
    z-index: 1; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    overflow: auto; 
}

.logout-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 40px;
    border-radius: 25px;
    box-shadow: 10px 10px 1px var(--mainColor);
    width: 400px;
    height: 150px;
}

.logout-box p {
    font-size: 20px;
    text-align: center;
}
.noDisplay{
    display: none;
}

.logout-buttons button {
    height: 40px;
    width: 120px;
    font-size: 20px;
    border-radius: 25px;
}

.logout-buttons a {
    color: black;
    text-decoration: none;
}

.stay {
    background-color: var(--mainColor);
    border: 1px solid var(--mainColor);
}

.stay:active {
    box-shadow: inset 0px 0px 5px #797979;
}

.stay:hover {
    font-weight: bold;
    background-color: var(--mainColor);
}

.leave {
    background-color: white;
    border: 1px solid var(--mainColor);
}

.leave:hover {
    background-color: white;
}

.leave:active {
    box-shadow: inset 0px 0px 5px var(--mainColor);
}

.leave:hover {
    font-weight: bold;
}


/* < ICON > */

.wave {
    width: 90px;
}

/* </ ICON > */


/* < RESPONSIVE > */

@media (max-width:510px) {
    .logout-box {
        width: 298px;
    }
}

@media (max-width:400px) {
    .logout-box {
        width: 250px;
    }
}

/* </ RESPONSIVE > */

